import React, { useEffect, useState } from 'react';
import './App.css';
import loaderImage from './assets/loader.gif'; // Ensure this path is correct

function App() {
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const queryParams = new URLSearchParams(window.location.search);
  const data = queryParams.get('link-seq');

  useEffect(() => {
    if (!data) {
      setError('No data query parameter provided.');
      // setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.icepe.in/MerchantPanel/merchant/link/get/${data}`);
        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`);
        }
        const result = await response.json();
        setApiResponse(result);
      } catch (error) {
        setError(error.message);
      } finally {
        
      }
    };

    fetchData();
  }, [data]);
  useEffect(() => {
    // Redirect based on the updated apiResponse state
      if (apiResponse && apiResponse.statusCode === 200) {
    
        // Assuming your API response includes the URL and form data to redirect
        const redirectUrl = "https://pg.icepe.in/paymentrequest"; // Your target URL for redirection
        const formData = apiResponse.data; // Assuming this is the correct path to your form data in the response
        const queryString = new URLSearchParams(formData).toString();

        window.location.href = `${redirectUrl}?${queryString}`;
      }
  }, [apiResponse]); // This useEffect is triggered when apiResponse is updated

  if (loading) {
    return <div style={{
      background: `url(${loaderImage}) no-repeat center center`,
      width: '15vw',
      height: '15vh',
      alignItems:'center',
      position:'relative',
      top: 400,
      left: 850,
    }} />;
  }

  return (
    <div className="App">
    {loading ? (
           <img src={loaderImage} alt="Loading..." />
         ) : (
           <p></p>
         )}
     </div>

  );
}

export default App;
